:root {
  --color-1: #f9fbfd;
  --color-2: #edf2fa;
  --color-3: #c2e7ff;
  --color-4: #bad3fc;
  --color-5: #2684fc;
  --color-6: #4f709c;
  --color-7: #5a7a7a;
  --color-8: #929292;
  --black: #181919;
}

::selection {
  background-color: var(--color-4);
  color: var(--black);
}

.container {
  width: 800px;
  margin: 0 auto;
}

html {
  font-family: Roboto, sans-serif;
}

body {
  background-color: var(--color-1);
  margin: 0;
}

.vertical-menu {
  background-color: var(--color-2);
  display: none;
}

.vertical-menu ul {
  text-align: center;
  margin: 0;
  padding: 10px;
  list-style: none;
  display: block;
}

.vertical-menu li {
  margin: 5px 0;
}

.vertical-menu li:hover {
  background-color: var(--color-3);
}

.vertical-menu-button {
  border-radius: 10px;
  padding: 15px;
  display: none;
}

.vertical-menu-button:hover {
  background-color: var(--color-3);
  color: var(--black);
  border-radius: 10px;
}

header {
  z-index: 1;
  background-color: var(--color-1);
  filter: drop-shadow(0px 8px 5px var(--color-2));
  position: sticky;
  top: 0;
}

header nav {
  justify-content: space-between;
  align-items: center;
  width: 800px;
  margin: 0 auto;
  display: flex;
}

header ul {
  justify-content: flex-start;
  padding: 0;
  list-style: none;
  display: flex;
}

header a {
  color: var(--color-6);
  padding-right: 20px;
  font-size: 16pt;
  text-decoration: none;
}

header a:hover {
  color: var(--color-7);
}

.lang {
  color: var(--color-7);
  font-size: 10pt;
}

main {
  background-color: var(--color-1);
  height: auto;
  min-height: 100vh;
}

.logo {
  align-items: center;
  display: flex;
}

.logo img {
  height: 150px;
}

.logo h1 {
  padding: 0;
}

.description {
  background-color: var(--color-2);
  padding-top: 25px;
  padding-bottom: 25px;
}

.description h1 {
  color: var(--color-6);
  font-family: Tilt Warp, sans-serif;
  font-size: 36pt;
  font-weight: 700;
}

.description h3 {
  color: var(--color-7);
  font-size: 16pt;
  font-style: italic;
  font-weight: 400;
}

.marketplace-button {
  height: 68px;
}

.features {
  background-color: var(--color-4);
  background-image: url("background.7786dcf4.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 100px;
}

.features h1 {
  color: var(--color-6);
  font-size: 24pt;
  font-weight: 700;
}

.features h2 {
  color: var(--black);
  font-size: 16pt;
  font-weight: 700;
}

.right {
  text-align: right;
  margin-left: auto;
  display: block;
}

.example {
  filter: drop-shadow(10px 10px 5px var(--color-7));
  border-radius: 10px;
  width: 750px;
}

.contact {
  background-color: var(--color-2);
  padding-top: 50px;
  padding-bottom: 25px;
}

.contact h1 {
  color: var(--black);
  font-size: 24pt;
  font-weight: 700;
}

.contact h2 {
  color: var(--color-6);
  font-size: 16pt;
  font-weight: 700;
}

.contact a {
  color: var(--color-6);
  text-decoration: none;
}

.contact a:hover {
  color: var(--color-7);
}

.contact a:active {
  color: var(--color-6);
}

.terms {
  background-color: var(--color-2);
  padding-top: 25px;
  padding-bottom: 25px;
}

.terms h1 {
  color: var(--color-6);
  font-size: 24pt;
  font-weight: 700;
}

.terms h2 {
  color: var(--black);
  font-size: 18pt;
  font-weight: 700;
}

.footer {
  text-align: center;
  padding: 25px 0;
}

.footer p {
  color: var(--color-7);
  line-height: 200%;
}

.footer a {
  color: var(--black);
  text-decoration: none;
}

.footer a:hover {
  color: var(--color-7);
}

.footer a:active {
  color: var(--color-6);
}

.footer-tag {
  font-size: smaller;
}

.legal {
  text-align: left;
  font-size: small;
}

.donate-button {
  background-color: var(--color-4);
  color: var(--black);
  border-radius: 50px;
  width: 30%;
  padding: 10px 20px;
  font-size: 14pt;
  font-weight: 700;
  text-decoration: none;
}

.donate-button:hover {
  background-color: var(--color-3);
}

@media screen and (width <= 900px) {
  .container, header nav {
    width: 90%;
  }

  .features h1 {
    font-size: 20pt;
  }

  .features h2 {
    font-size: 12pt;
  }

  .example {
    width: 100%;
  }

  .footer {
    font-size: small;
  }

  .footer-tag {
    font-size: x-small;
  }
}

@media screen and (width <= 580px) {
  .vertical-menu-button {
    display: block;
  }

  header ul {
    display: none;
  }
}

@media screen and (width <= 430px) {
  .logo img {
    height: 100px;
  }

  .marketplace-button {
    height: 50px;
  }
}

@media screen and (width <= 320px) {
  .footer-tag {
    font-size: xx-small;
  }
}

.convert-example {
  border: 1px solid var(--color-8);
  background-color: var(--color-1);
  margin: 10px 0;
  padding: 10px 15px;
}

.run-animation {
  background-color: none;
  background: linear-gradient(90deg, var(--color-4) 50%, #fff0 50%);
  background-position: -100% 0;
  background-size: 200% 100%;
  animation: 1.5s 1.5s forwards highlight;
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}

mark {
  background: none;
}

/*# sourceMappingURL=index.e17c36ac.css.map */
